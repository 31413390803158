<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { userMethods } from '@/state/helpers'
import { getApi } from '@/api'
import vue2Dropzone from 'vue2-dropzone'

/**
 * Account Create Wizard
 */
export default {
  page: {
    title: 'Account Create Wizard',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  components: {
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    FormWizard,
    TabContent
  },
  data () {
    return {
      title: this.$t('pages.accountCreate.title'),
      items: [
        {
          text: this.$t('general.account'),
          href: '/'
        },
        {
          text: this.$t('pages.accountCreate.title'),
          active: true
        }
      ],
      flags: {
        isAdmin: false
      },
      dropzoneOptions: {
        url: 'https://api.vrsteps.co/upload.php',
        thumbnailWidth: 150,
        maxFilesize: 0.3,
        resizeQuality: 0.75,
        acceptedFiles: 'image/*',
        maxFiles: 1,
        headers: {
          'User': this.$store.state.user.id,
          'Token': this.$store.state.user.access_token
        }
      },
      account: {
        email: '',
        first_name: '',
        last_name: '',
        type: 'therapist',
        country: 'IL',
        birth_year: 1990,
        gender: 1,
        weight: 75,
        height: 175,
        image: null
      },
      acceptedTerms: false
    }
  },
  methods: {
    ...userMethods,
    ...getApi(),
    validateStep1: function () {
      return this.account.first_name.length > 2 && this.account.last_name.length > 2 && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.account.email)
    },
    validateStep3: function () {
      return this.acceptedTerms
    },
    onComplete: function () {
      let userId = this.$store.state.user.id
      let accessToken = this.$store.state.user.access_token
      this.createAccount(userId, accessToken, this.account).then(result => {
        if (result.status) {
          this.$router.push(`/`)
        } else {
          alert(result.error)
        }
      })
    },
    onFileSuccess: function (file, response) {
      if (response.status) {
        this.account.image = response.url
      }
    }
  },
  mounted () {
    this.isAdmin().then(flag => this.flags.isAdmin = flag)
  }
}
</script>

<style scoped lang="scss">
.form-container {
  max-width: 760px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="form-container">
      <div class="card">
        <div class="card-body">
          <form-wizard color="#556ee6" :backButtonText="$t('form.back')"
                       :finishButtonText="$t('form.finish')"
                       :nextButtonText="$t('form.next')" @on-complete="onComplete">
            <tab-content icon="mdi mdi-account-circle" :before-change="validateStep1">
              <div class="row">
                <div class="col-12">
                  <h3>{{ $t('pages.accountCreate.requiredTitle') }}</h3>
                  <p class="mb-5">{{ $t('pages.accountCreate.requiredText') }}</p>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="firstName">{{ $t('form.firstName') }}</label>
                    <div class="col-md-9">
                      <input type="text" name="firstName" class="form-control" value="" required
                             v-model="account.first_name"/>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="lastName">{{ $t('form.lastName') }}</label>
                    <div class="col-md-9">
                      <input type="text" name="lastName" class="form-control" value="" required
                             v-model="account.last_name"/>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="email">{{ $t('form.email') }}</label>
                    <div class="col-md-9">
                      <input
                          type="email"
                          name="email"
                          class="form-control"
                          v-model="account.email"
                          required
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="userName">{{ $t('form.accountType') }}</label>
                    <div class="col-md-9">
                      <select id="type" class="form-control" name="type" required v-model="account.type">
                        <option value="patient" selected>{{ $t('form.type.patient') }}</option>
                        <option value="therapist" v-if="flags.isAdmin">{{ $t('form.type.therapist') }}</option>
                        <option value="admin" v-if="flags.isAdmin">{{ $t('form.type.admin') }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </tab-content>
            <tab-content icon="mdi mdi-face-profile">
              <div class="row">
                <div class="col-12">
                  <h3>{{ $t('pages.accountCreate.optionalTitle') }}</h3>
                  <p class="mb-5">{{ $t('pages.accountCreate.optionalText') }}</p>
                </div>
                <div class="col-md-7">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="country">{{ $t('form.country') }}</label>
                    <div class="col-md-9">
                      <select id="country" class="form-control custom-select" title="Country"
                              v-model="account.country">
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Cote d'Ivoire</option>
                        <option value="HR">Croatia (Hrvatska)</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IL" selected>Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macau</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PW">Palau</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Reunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint LUCIA</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia (Slovak Republic)</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SH">St. Helena</option>
                        <option value="PM">St. Pierre and Miquelon</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands (British)</option>
                        <option value="VI">Virgin Islands (U.S.)</option>
                        <option value="WF">Wallis and Futuna Islands</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="birthYear">{{ $t('form.birthYear') }}</label>
                    <div class="col-md-9">
                      <select name="birthYear" class="form-control custom-select" v-model="account.birth_year">
                        <option :key="i" :value="i+1921" :selected="i===69"
                                v-for="i in Array.from(Array(100).keys()).sort((a,b)=> a>b? -1:1)">
                          {{ i + 1921 }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="gender">{{ $t('form.genderType') }}</label>
                    <div class="col-md-9">
                      <select id="gender" class="form-control custom-select" name="type" v-model="account.gender">
                        <option value="1" selected>{{ $t('form.gender.male') }}</option>
                        <option value="2">{{ $t('form.gender.female') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="weight">{{ $t('form.weight') }} (kg)</label>
                    <div class="col-md-9">
                      <input v-model="account.weight"
                             type="number"
                             name="weight"
                             min="0"
                             max="250"
                             class="form-control"
                             value="75"
                      />
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="height">{{ $t('form.height') }} (cm)</label>
                    <div class="col-md-9">
                      <input v-model="account.height"
                             type="number"
                             name="height"
                             min="50"
                             max="250"
                             step="5"
                             class="form-control"
                             value="120"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-4">
                  <div class="form-group mb-3">
                    <vue-dropzone
                        id="imageDrop"
                        ref="imageropzone"
                        :use-custom-slot="true"
                        :options="dropzoneOptions"
                        @vdropzone-success="onFileSuccess"
                    >
                      <div class="dropzone-custom-content">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                        <h4>{{ $t('form.imageDescription') }}</h4>
                      </div>
                    </vue-dropzone>
                    <label class="col-form-label">{{ $t('form.image') }}</label>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </tab-content>
            <tab-content icon="mdi mdi-checkbox-marked-circle-outline" :before-change="validateStep3">
              <div class="row">
                <div class="col-12">
                  <div class="text-center">
                    <h2 class="mt-0">
                      <i class="mdi mdi-check-all"></i>
                    </h2>
                    <h3 class="mt-0">{{ $t('pages.accountCreate.almostDone') }}</h3>

                    <p class="w-75 mb-2 mx-auto">
                      {{ $t('pages.accountCreate.disclaimer') }}
                    </p>

                    <div class="mb-3">
                      <div class="custom-control custom-checkbox">
                        <input id="customCheck1" type="checkbox" class="custom-control-input" required
                               v-model="acceptedTerms"/>
                        <label
                            class="custom-control-label"
                            for="customCheck1"
                        >{{ $t('pages.accountCreate.agreeLabel') }} <a href="https://www.vrsteps.co/terms-of-use"
                                                                       target='_blank'>Terms of Use</a>.</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </tab-content>
          </form-wizard>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
